<template>
  <div>
    <CRow>
      <CCol sm="12">
        <TicketsTable
          :items="tickets"
          :stage="code"
          hover
          striped
          border
          small
          fixed
          :caption="title"
          @refresh="refreshItems"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import TicketsTable from '../../components/tickets/TicketsTable.vue'
import tickets from '../../services/tickets';
import store from '../../store'

export default {
  name: 'Tickets',
  components: { TicketsTable },
  data: function () {
		return {
        code: 'active',
        title: 'Reportes Activos',
        tickets: [],
        user: null
    }
  },
  mounted: async function() {
    this.loading();
    this.code = await this.$route.params.code;

    this.user = JSON.parse(localStorage.getItem("user"));

    this.title = "Reportes "+(this.code == "active" ? "Activos" : (this.code == "new" ? "Nuevos" : "Terminados"))

    let response = await tickets.getByCode(this.code, this.user.direction_id);

    if(response.type == "success"){
      this.tickets = response.data; 
    }

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      console.log(items);
      this.loading();
      this.tickets = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
